@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: url('./assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 500;
  src: url('./assets/fonts/OpenSans-Medium.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: url('./assets/fonts/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  src: url('./assets/fonts/OpenSans-Bold.ttf');
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}

:root {
  --primary: #283A97;
  --primary-light: rgba(40, 58, 151, 0.05);
  --gray: #AAAAAA;
  --shadow: rgba(100, 163, 198, 0.1);
}

body {
  background: #FBFBFB;
  overscroll-behavior: none;
}

.App {
  min-height: 100vh;
  background: #FBFBFB;
  display: flex;
}

.main {
  margin-left: 340px;
  flex: 1;
  padding: 0 30px;
}

.main__container {
  max-width: 860px;
  margin: 0 auto;
  padding-top: 150px;
}

.title,
h1 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
}

.text {
  margin-bottom: 25px;
  font-size: 18px;
}

.text strong {
  font-size: 20px;
  font-weight: 600;
}

.button {
  height: 44px;
  width: 200px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  font-weight: 500;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.button_outlined {
  height: 44px;
  width: 200px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--primary) 2px solid;
  font-weight: 500;
  color: var(--primary);
  background: none;
  font-size: 16px;
  cursor: pointer;
}

h3 {
  font-weight: 600;
}

ul {
  margin-left: 60px;
}

.text>ul {
  margin-left: 0;
}

@media (max-width: 960px) {
  .main__container {
    padding-top: 80px;
  }

  .main {
    margin-left: 0px;
  }
}