.test__answers-title {
  font-size: 28px;
  margin-bottom: 10px;
  font-weight: 700;
  margin-top: 25px;
}

.test__answers-item-title {
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: 600;
}

.test__answers-item {
  margin-bottom: 20px;
}

.test__answers-input {
  margin-bottom: 15px;
}

.select {
  width: 575px;
}

.test__answers-input label {
  display: inline-block;
  width: 65px;
  font-size: 18px;
  text-align: right;
  margin-right: 10px;
}

.test__answers-input input {
  height: 44px;
  padding: 0 15px;
  border-radius: 10px;
  outline: none;
  font-size: 18px;
  width: 500px;
}

.test__answers-answer {
  font-size: 22px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ant-modal-title {
  font-size: 26px !important;
  font-weight: 700 !important;
}

.ant-modal-content {
  border-radius: 15px !important;
}

.select__error {
  font-size: 16px;
  color: red;
  margin-top: 5px;
}

.test__loading {
  text-align: center;
}

ul {
  margin-top: 5px;
}

ul+p {
  margin-top: 15px;
}

h3,
h2 {
  margin-bottom: 10px;
}

@media (max-width: 960px) {

  .test__answers-input {
    display: flex;
  }

  .test__answers-input input {
    width: 100%;
  }

  .ant-select {
    width: 100% !important;
  }

  .test__image {
    height: auto !important;
    max-height: 485px;
  }

  .test__image~span {
    font-size: 1.8vw !important;
  }
}

.float-button {
  right: 50;
  bottom: 50;
}