.sidebar {
  background: #fff;
  width: 340px;
  height: 100vh;
  position: fixed;
  box-shadow: 0 0 20px var(--shadow);
  padding: 50px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;

  &__logo {
    margin-bottom: 40px;
  }

  &__back-button {
    background: var(--primary);
    border: none;
    border-radius: 10px;
    width: 100%;
    display: flex;
    padding: 0 20px;
    align-items: center;
    height: 46px;
    cursor: pointer;

    &-text {
      font-size: 16px;
      color: #fff;
      margin-left: 10px;
    }
  }

  &__footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.menu {
  display: flex;
  width: 100%;
  list-style: none;
  flex-direction: column;
  gap: 5px;

  &__item {
    height: 46px;
    border-radius: 10px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #f9f9f9;
    }

    &.active {
      background: var(--primary-light);
    }

    &.disabled {
      background: #f1f1f1;
      cursor: not-allowed;
    }

    &-text {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.menu__item.active .menu__item-text {
  color: var(--primary);
  font-weight: 600;
}

.menu__item.disabled .menu__item-text {
  color: var(--gray);
}

.ant-menu {
  border-inline-end: none !important;
}

.ant-menu-title-content {
  font-size: 18px;
  font-weight: 500;
}

.ant-menu-item {
  height: 46px !important;
}

.ant-menu-submenu,
.ant-menu-submenu-title {
  min-height: 46px !important;
}

.ant-menu {
  background: none !important;
  user-select: none !important;
}

.ant-menu-sub>.ant-menu-item {
  margin-left: 20px !important;
  width: calc(100% - 28px);
  padding-left: 15px !important;
}

.ant-menu-submenu-selected .ant-menu-title-content {
  color: var(--primary) !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-title {
  background-color: var(--primary-light) !important;
}

.ant-menu-item-selected {
  background-color: var(--primary-light) !important;
}

.ant-menu-item .ant-menu-title-content {
  color: #000 !important;
}

.ant-menu-item-selected .ant-menu-title-content {
  color: var(--primary) !important;
}

.ant-menu-item-disabled .ant-menu-title-content {
  color: #00000040 !important;
}

.ant-menu-submenu-arrow {
  color: #000 !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-arrow {
  color: var(--primary) !important;
}

.ant-menu-submenu-disabled .ant-menu-submenu-arrow {
  color: #00000040 !important;
}

.sibebar__header-button {
  background: none;
  border: none;
  cursor: pointer;
}



@media (max-width: 960px) {
  .sidebar {
    position: relative;
    width: 100%;
    height: 80px;
    padding: 20px 25px;

    &.opened {
      position: fixed;
      height: 100vh;
      padding-bottom: 50px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-bottom: 40px;
    }

    &__logo {
      margin-bottom: 0;
    }
  }
}