.finish {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 15px;
  text-align: center;
}

.finish__icon {
  margin-bottom: 50px;
}

.finish__title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 5px;
}

.finish__text {
  font-size: 18px;
  color: #aaa;
  margin-bottom: 30px;
  max-width: 600px;
}

.finish__button {
  width: auto !important;
  padding: 0 25px;
}
