@keyframes leave {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes enter {
  from {
    transform: translateX(50%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes leave_back {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes enter_back {
  from {
    transform: translateX(-50%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.page.enter {
  animation: enter 500ms forwards;
}

.page.leave {
  animation: leave 500ms forwards;
}

.page.enter_back {
  animation: enter_back 500ms forwards;
}

.page.leave_back {
  animation: leave_back 500ms forwards;
}

.page__footer {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  margin-bottom: 150px;
  margin-top: 40px;
}

.page__image {
  width: 100%;
}

.page__list {
  padding-left: 30px;
}

.page__list-item {
  margin-bottom: 30px;
}

.page__list-item h2 {
  margin-left: -30px;
  margin-bottom: 10px;
}

.page__list-item ul {
  padding-left: 25px;
}

.page__list-item li {
  margin-top: 5px;
}